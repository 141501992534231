// Copyright © 2022 Move Closer

import { Component, PropSync, Vue } from 'vue-property-decorator'

import { HeroAddons } from '../Hero.contracts'

/**
 * AbstractAddon component that every HeroAddon **SHOULD** extend
 *
 * @property _item - addon item's content
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<AbstractAddon>({ name: 'AbstractAddon' })
export class AbstractAddon extends Vue {
  /**
   * TODO: Documentation.
   */
  @PropSync('item', { type: Object, required: true })
  public _item!: HeroAddons
}
