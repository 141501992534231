

















































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractAddon } from '../AbstractAddon.form'

import { HeadingAddon, HeadingVariant } from './Heading.contracts'
import { HeadingFont } from '../../../../atoms/UiHeading/UiHeading.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<HeadingAddonForm>({ name: 'HeadingAddonForm' })
export class HeadingAddonForm extends AbstractAddon {
  public get variantOptions (): DashmixSelectItem[] {
    return [...Object.entries(HeadingVariant).map(([label, value]) => {
      return {
        label: String(this.$t(`forms.Hero.addons.Heading.variant.options.${label}`)),
        value
      }
    })]
  }

  public get fontOptions (): DashmixSelectItem[] {
    return Object.values(HeadingFont).map((value: HeadingFont) => {
      return {
        label: this.$t(`forms.Heading.font.option.${value}`) as string,
        value
      }
    })
  }

  public onContentUpdate (content: string): void {
    this._item = { ...this._item, content } as HeadingAddon
  }

  public onVariantUpdate (variant: HeadingVariant): void {
    this._item = { ...this._item, variant } as HeadingAddon
  }

  public onFontUpdate (font: HeadingFont): void {
    this._item = { ...this._item, font } as HeadingAddon
  }
}

export default HeadingAddonForm
